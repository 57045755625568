import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Paper } from "@material-ui/core";
import { Row, Col, Button, Form, Dropdown, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search, MoreHoriz } from "@material-ui/icons";
import useDebounce from "../../../hooks/useDebounce";
import { toast } from "react-toastify";

import EditWifiModal from "./EditWifiModal"; // We'll create this next
import ShowConfirmModal from "../../../components/ConfirmModal";

export const WifiTab = () => {
  const [loading, setLoading] = useState(false);
  const [stateEditModal, setStateEditModal] = useState(false);
  const [stateDeleteModal, setStateDeleteModal] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    time: "newest"
  });
  const [allOutlets, setAllOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const debouncedSearch = useDebounce(search, 1000);

  const allStatuses = ["Newest", "Oldest"];

  // Fetch outlets with their wifi settings
  const getOutlets = async (search, filter) => {
    const filterOutlet = `?name=${search}&order=${filter.time}`;
    try {
      const { data } = await axios.get(`${API_URL}/api/v1/outlet${filterOutlet}`);
      
      // Fetch wifi settings for each outlet
      const outletsWithWifi = await Promise.all(
        data.data.map(async (outlet) => {
          try {
            const wifiResponse = await axios.get(
              `${API_URL}/api/v1/retinad-setting/by-outlet/${outlet.id}`
            );
            return {
              ...outlet,
              wifi_settings: wifiResponse.data.data
            };
          } catch (error) {
            return {
              ...outlet,
              wifi_settings: null
            };
          }
        })
      );
      
      setAllOutlets(outletsWithWifi);
    } catch (err) {
      console.log("Error fetching outlets:", err);
      setAllOutlets([]);
    }
  };

  useEffect(() => {
    getOutlets(debouncedSearch, filter);
  }, [debouncedSearch, filter]);

  const handleSearch = (e) => setSearch(e.target.value);
  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilter(prev => ({...prev, [name]: value}));
  };

  const showEditModal = (data) => {
    setSelectedOutlet(data);
    setStateEditModal(true);
  };

  const cancelEditModal = () => {
    setSelectedOutlet(null);
    setStateEditModal(false);
  };

  const showDeleteModal = (data) => {
    setSelectedOutlet(data);
    setStateDeleteModal(true);
  };

  const cancelDeleteModal = () => {
    setSelectedOutlet(null);
    setStateDeleteModal(false);
  };

  const handleDelete = async () => {
    if (!selectedOutlet?.wifi_settings?.id) return;
    
    try {
      setLoading(true);
      await axios.delete(
        `${API_URL}/api/v1/retinad-setting/${selectedOutlet.wifi_settings.id}`
      );
      toast.success(t("wifiSettingsDeletedSuccessfully"));
      getOutlets(search, filter);
      setLoading(false);
      cancelDeleteModal();
    } catch (error) {
      console.log("Error deleting wifi settings:", error);
      toast.error(t("failedToDeleteWifiSettings"));
      setLoading(false);
    }
  };

  const bytesToMB = (bytes) => bytes ? (bytes / (1024 * 1024)).toFixed(2) : 0;

  const columns = [
    {
      name: t("no"),
      selector: "no",
      sortable: true,
      width: "70px"
    },
    {
      name: t("outletName"),
      selector: "name",
      sortable: true
    },
    {
      name: t("downloadRate"),
      cell: (row) => `${bytesToMB(row.wifi_settings?.download_rate || 0)} MB/s`,
      sortable: true
    },
    {
      name: t("uploadRate"),
      cell: (row) => `${bytesToMB(row.wifi_settings?.upload_rate || 0)} MB/s`,
      sortable: true
    },
    {
      name: t("expireTime"),
      cell: (row) => `${row.wifi_settings?.expire_time || 0} ${t("seconds")}`,
      sortable: true
    },
    {
      name: t("actions"),
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="secondary">
            <MoreHoriz color="action" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as="button" onClick={() => showEditModal(row)}>
              {t("edit")}
            </Dropdown.Item>
            {row.wifi_settings && (
              <Dropdown.Item as="button" onClick={() => showDeleteModal(row)}>
                {t("delete")}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  ];

  const dataOutlets = () => {
    return allOutlets.map((item, index) => ({
      id: item.id,
      no: index + 1,
      name: item.name,
      wifi_settings: item.wifi_settings,
      business_id: item.business_id
    }));
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  return (
    <>
      <EditWifiModal
        t={t}
        stateModal={stateEditModal}
        cancelModal={cancelEditModal}
        outlet={selectedOutlet}
        handleRefresh={() => getOutlets(search, filter)}
      />

      <ShowConfirmModal
        t={t}
        state={stateDeleteModal}
        closeModal={cancelDeleteModal}
        title={`${t("deleteWifiSettings")} - ${selectedOutlet?.name}`}
        body={t("areYouSureWantToDelete?")}
        loading={loading}
        buttonColor="danger"
        handleClick={handleDelete}
      />

      <Row>
        <Col>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div className="headerPage">
              <div className="headerStart">
                <h3>{t("wifiManagement")}</h3>
              </div>
            </div>

            <div className="filterSection lineBottom">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder={t("search")}
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group as={Row}>
                        <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                          {t("time")}:
                        </Form.Label>
                        <Col>
                          <Form.Control
                            as="select"
                            name="time"
                            value={filter.time}
                            onChange={handleFilter}
                          >
                            {allStatuses.map((item, index) => (
                              <option key={index} value={item.toLowerCase()}>
                                {t(item.toLowerCase())}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataOutlets()}
              style={{ minHeight: "100%" }}
              noDataComponent={t("thereAreNoRecordsToDisplay")}
              customStyles={customStyles}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
};

export default WifiTab;
