import React from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Button, Form, Spinner, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const EditWifiModal = ({
  stateModal,
  cancelModal,
  outlet,
  handleRefresh,
  t
}) => {
  const [loading, setLoading] = React.useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const initialValues = {
    amount: 1,
    expire_time: 1800,
    download_rate: 1500000,
    upload_rate: 500000,
    timeout: 600
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, t("minimumAmount"))
      .required(t("pleaseInputAmount")),
    expire_time: Yup.number()
      .min(60, t("minimumExpireTime"))
      .required(t("pleaseInputExpireTime")),
    download_rate: Yup.number()
      .min(100000, t("minimumDownloadRate"))
      .required(t("pleaseInputDownloadRate")),
    upload_rate: Yup.number()
      .min(100000, t("minimumUploadRate"))
      .required(t("pleaseInputUploadRate")),
    timeout: Yup.number()
      .min(60, t("minimumTimeout"))
      .required(t("pleaseInputTimeout"))
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        outlet_id: outlet?.id,
        business_id: outlet?.business_id,
        venue_slug: "lifetech",
        port_limit: 1
      };

      try {
        setLoading(true);
        if (outlet?.wifi_settings?.id) {
          await axios.put(
            `${API_URL}/api/v1/retinad-setting/${outlet.wifi_settings.id}`,
            payload
          );
          toast.success(t("wifiSettingsUpdatedSuccessfully"));
        } else {
          await axios.post(`${API_URL}/api/v1/retinad-setting`, payload);
          toast.success(t("wifiSettingsAddedSuccessfully"));
        }
        handleRefresh();
        cancelModal();
      } catch (error) {
        console.log("Error submitting wifi settings:", error);
        toast.error(t("failedToSaveWifiSettings"));
      } finally {
        setLoading(false);
      }
    }
  });

  const validationField = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  React.useEffect(() => {
    if (outlet?.wifi_settings) {
      formik.setValues({
        amount: outlet.wifi_settings.amount,
        expire_time: outlet.wifi_settings.expire_time,
        download_rate: outlet.wifi_settings.download_rate,
        upload_rate: outlet.wifi_settings.upload_rate,
        timeout: outlet.wifi_settings.timeout
      });
    } else {
      formik.resetForm();
    }
  }, [outlet]);

  return (
    <Modal show={stateModal} onHide={cancelModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {outlet?.wifi_settings
            ? `${t("editWifiSettings")} - ${outlet.name}`
            : `${t("addWifiSettings")} - ${outlet?.name}`}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{t("amount")}:</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  {...formik.getFieldProps("amount")}
                  className={validationField("amount")}
                  required
                />
                {formik.touched.amount && formik.errors.amount && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.amount}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>{t("expireTime")} (seconds):</Form.Label>
                <Form.Control
                  type="number"
                  name="expire_time"
                  {...formik.getFieldProps("expire_time")}
                  className={validationField("expire_time")}
                  required
                />
                {formik.touched.expire_time && formik.errors.expire_time && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.expire_time}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>{t("downloadRate")} (bytes/s):</Form.Label>
                <Form.Control
                  type="number"
                  name="download_rate"
                  {...formik.getFieldProps("download_rate")}
                  className={validationField("download_rate")}
                  required
                />
                <Form.Text className="text-muted">
                  {t("example")}: 1 MB/s = 1,048,576 bytes/s
                </Form.Text>
                {formik.touched.download_rate && formik.errors.download_rate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.download_rate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>{t("uploadRate")} (bytes/s):</Form.Label>
                <Form.Control
                  type="number"
                  name="upload_rate"
                  {...formik.getFieldProps("upload_rate")}
                  className={validationField("upload_rate")}
                  required
                />
                <Form.Text className="text-muted">
                  {t("example")}: 1 MB/s = 1,048,576 bytes/s
                </Form.Text>
                {formik.touched.upload_rate && formik.errors.upload_rate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.upload_rate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label>{t("timeout")} (seconds):</Form.Label>
                <Form.Control
                  type="number"
                  name="timeout"
                  {...formik.getFieldProps("timeout")}
                  className={validationField("timeout")}
                  required
                />
                {formik.touched.timeout && formik.errors.timeout && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.timeout}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModal}>
            {t("cancel")}
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              t("save")
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditWifiModal; 